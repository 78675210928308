import { fetchWrapper } from "../utils/fetch-wrapper";

export const giftcardService = {
  checkGiftCardbalance,
  setSessionOrders,
  handleGiftCardPayment
};

// const baseUrl = `${apiUrl}/users`;
// const baseUrl = "http://dev.siapi.com/public/";
const baseUrl = "https://ecommv2.servingintel.com/";
const siteId = 89;

async function checkGiftCardbalance(params) {
  let formData = new URLSearchParams();

  formData.append("giftcard_number", params.giftcard_number);
  formData.append("giftcard_cvc", params.giftcard_cvc);
  formData.append("orderpayment_amount", 10);

  return await fetchWrapper.post(`${baseUrl}giftcards/checkbalance`, formData);
}

async function setSessionOrders(params) {
  let formData = new URLSearchParams();
  let cartItems = params.cartItems;
  let customerData = params.selectedAddress;
  let paymentsData = params.payments;

  Object.keys(cartItems).forEach((key) => {
    // for (var items in cartItems[key]) {
    //   if (items == "modifiers") {
    //     let mods = cartItems[key][items];
    //     for (var mod in mods) {
    //       Object.keys(cartItems[key][items][mod]).forEach((idx, modVal) => {
    //         formData.append(
    //           `cartItems[${key}][${items}][${mod}][${idx}]`,
    //           mods[mod][idx]
    //         );
    //       });
    //     }
    //   } else {
    //     formData.append(`cartItems[${key}][${items}]`, cartItems[key][items]);
    //   }
    // }
  });

  Object.keys(customerData).forEach((k) => {
    formData.append(`selectedAddress[${k}]`, customerData[k]);
  });

  formData.append("pid", params.pid);
  formData.append("tips", params.tips);
  formData.append("promoCode", params.promoCode);
  formData.append("total", params.total);
  formData.append("subTotal", params.subTotal);
  formData.append("instructions", params.instructions);
  formData.append("orderProcessingType", params.orderProcessingType);
  formData.append("orderProcessingDate", params.orderProcessingDate);
  formData.append("order_id", params.orderId);

  Object.keys(paymentsData).forEach((key) => {
    // for (var items in paymentsData[key]) {
    //   formData.append(`payments[${key}][${items}]`, paymentsData[key][items]);
    // }
  });

  return await fetchWrapper.post(
    `${baseUrl}giftcards/${siteId}/saveOrders`,
    formData
  );
}

async function handleGiftCardPayment(params) {
  let formData = new URLSearchParams();
  formData.append("order_id", params.order_id);
  formData.append("user_id", params.user_id);
  formData.append("data", params.data);

  return await fetchWrapper.post(
    `${baseUrl}giftcards/${siteId}/paymentAndSendtoEconnect`,
    formData
  );
}
