const config = {

    // Landing Page
    landingcontent: {
        title: "Unwind at Danny's Bar & Grill",
        subtitle: "Dive into Fun with Darts and Pool, Where Exceptional Service Enhances Your Enjoyment!",
        description: "Come join us at Danny's Bar & Grill and take part in our awesome activities like darts and pool! Our service-oriented staff will be sure to make your experience enjoyable.",
        keywords: "Danny's Bar & Grill, Effingham, Wine, Beer, Spirits Store, Appetizers, Salads, Wraps, Soups, Food, Drinks, Games, Music, Nightlife, Entertainment, Live Music, Karaoke, Happy Hour, Cocktails, Recommendations, Chefs, Bartenders, Expertise, Community, Great Food, Libations, Activities, Local Favorite, Saturday Night, Dance Floor, Pool, Burgers, Dining Experience, Menu, Dedicated, Music Lovers, Game Enthusiasts, Video Slots, Fresh Ingredients, Sandwiches, Wings, Regulars, Unforgettable Night",
    },

    // About Us Page
    aboutcontent: {
        title: "Effingham's Premier Destination: 23 Years of Unforgettable Experiences",
        subtitle: "Indulge, Celebrate, and Elevate at Our Vibrant Establishment Since 2000",
        description: "Experience two decades of excellence at our beloved establishment in Effingham, where exceptional food, endless entertainment, and a vibrant atmosphere converge. Celebrate 23 years with us, indulging in mouthwatering dinners, thrilling games, and unforgettable moments, as our warm staff ensures each visit is a memorable occasion.",
        keywords: "Effingham, Premier destination, Unforgettable experiences, Excellence, Vibrant community, Over two decades, Beloved establishment, Exceptional food, Endless entertainment, Perfect drink, Lively atmosphere, Warm staff, Memorable occasions, Friday events, 23 years strong, Celebrate, Indulge, Inner adventurer, Thrilling games, Social experiences, Elevate, Effingham's best, Community hub, Culinary delight, Friday fun, Timeless tradition, Warm hospitality, Dining excellence, Entertainment hotspot, Anniversary celebration, Ultimate destination, Exceptional service, Culinary journey, Adventure awaits, Community spirit, Memorable moments, Dining extravaganza, Endless excitement, Culinary mastery, Social hub, Dynamic atmosphere, 23 years of memories, Weekend delight, Social elevation, Hospitality legacy, Foodie paradise, Culinary legacy, Celebratory spirit, Entertainment legacy, Effingham pride",
        message: "Cheers to 23 years of excellence! Join us in Effingham for mouthwatering dinners, thrilling games, and unforgettable experiences. Elevate your social adventures with us! 🎉🥂 #EffinghamExperiences #23YearsStrong",
    },
    aboutbusiness: {
        paragraph1: "Our doors opened over two decades ago, marking the beginning of a rich legacy in Effingham. Experience the vibrant community we've meticulously cultivated, where each visit becomes a cherished and memorable occasion.",
        paragraph2: "Indulge in mouthwatering dinners and immerse yourself in the excitement of our Friday fun-filled events, establishing us as the ultimate destination for unforgettable experiences. Since 2000, we've proudly served as the go-to bar for exceptional food and endless entertainment, creating a space where every moment is tailored to exceed expectations.",
        paragraph3: "Immerse yourself in our lively atmosphere, complemented by our warm and attentive staff, ensuring that your time with us is truly unparalleled. Celebrating 23 years of excellence, we stand as the preferred spot in Effingham for the perfect drink. Unleash your inner adventurer, engage in thrilling games, and enjoy the exceptional service provided by our welcoming team. Join us, and let us together elevate your social experiences to new heights.",
    },
    littleaboutus: {
        paragraph1: "Our doors opened over two decades ago, marking the beginning of a rich legacy in Effingham. Experience the vibrant community we've meticulously cultivated, where each visit becomes a cherished and memorable occasion.",
        paragraph2: "Indulge in mouthwatering dinners and immerse yourself in the excitement of our Friday fun-filled events, establishing us as the ultimate destination for unforgettable experiences. Since 2000, we've proudly served as the go-to bar for exceptional food and endless entertainment, creating a space where every moment is tailored to exceed expectations.",
        paragraph3: "Immerse yourself in our lively atmosphere, complemented by our warm and attentive staff, ensuring that your time with us is truly unparalleled. Celebrating 23 years of excellence, we stand as the preferred spot in Effingham for the perfect drink. Unleash your inner adventurer, engage in thrilling games, and enjoy the exceptional service provided by our welcoming team. Join us, and let us together elevate your social experiences to new heights.",
    },

    // Contact Page
    contactcontent: {
        title: "Your Ultimate Destination for Flavor and Hospitality in Effingham!",
        description: "Discover the unparalleled experience awaiting you at Danny's Bar & Grill in Effingham. Since 2000, we've been the go-to spot for delicious meals, refreshing drinks, and a vibrant atmosphere. From crispy wings to handcrafted cocktails, our diverse menu and lively entertainment options ensure there's something for everyone. Join us for a memorable evening filled with flavor, fun, and friendly faces.",
        keywords: "Bar and Grill, Restaurant in Effingham, Contact Information, Customer Inquiries, Community Hub, Dining Experience, Entertainment Venue, Food and Drinks, Reservations, Events and Specials, Happy Hour, Menu Questions, Staff Feedback, Party Reservations, Venue Hire, All-You-Can-Eat Buffet, Billiards and Pool, Karaoke Nights, Handcrafted Cocktails, Local Eats, Weekly Specials, Private Events, Group Bookings, Catering Services, Family-Friendly Atmosphere, Game Nights, Live Entertainment, Music Events, Dietary Requests, Loyalty Program, Gift Cards, Job Opportunities, Sponsorship Inquiries, Community Engagement, Event Planning, Reservation Policies, Lost and Found, Bar Hours, General Inquiries, Customer Satisfaction, Venue Accessibility, Donation Requests, Social Media Contacts, Fundraising Events, Takeout and Delivery, Loyalty Rewards, Vendor Collaborations, Marketing Partnerships, Special Occasion Celebrations, Customer Testimonials",
        message: "Danny's Bar & Grill is not just a place; it's a community of flavor and camaraderie. Join the excitement today!"
    },

    // Photo Gallery Page
    gallerycontent: {
        title: "Capturing the Flavorful Moments at Effingham's Favorite Spot",
        description: "Explore a visual feast of good times, great company, and mouthwatering dishes at Danny's Bar & Grill. From tantalizing cocktails to hearty meals, our photo gallery showcases the essence of comfort food and vibrant vibes that define us in Effingham. Join us on a visual journey through our delicious offerings and lively atmosphere!",
        keywords: "good food, great company, vibes, American dishes, fresh ingredients, bold flavors, stellar service, drinks, kickoff, comfort food, Monday vibes, whiskey, mixologists, all-you-can-eat buffet, kitchen delights, New Year's resolution, healthy drinks, first Friday, holiday wings, Christmas celebration, mid-week fun, delicious steaks, 5 o'clock somewhere, jolly time, share recipes, dining experience, friend tag, since 2000, Thanksgiving dinner, brews, local eats, Halloween festivities, college football, calorie-friendly, Friday fun, fruity drinks, National Vodka Day, diverse options, fun Friday, carry-out, karaoke night, parent's cheers, small business support, legacy building, Friday fun, National Whisky Sour Day, festive moments, beef perfection, National Rum Day, good times ahead, National White Wine Day, Effingham love, play and win",
        message: "Cheers to a year of unforgettable moments at Danny's Bar & Grill! 🎉📸 Join us in reliving the fun, flavors, and fantastic times captured in our photo gallery. Here's to many more memories to come!",
    },

    // Menu Page
    ordercontent: {
        title: "Serving Effingham for Over 22 Years with Delicious Food, Refreshing Drinks, and Unmatched Entertainment.",
        description: "Join us at Danny's Bar & Grill, your go-to spot in Effingham since 2000! From mouthwatering meals to lively entertainment, we offer an unforgettable experience. Whether it's a casual evening or a Friday night out, we've got the perfect blend of flavor and hospitality. Explore our diverse menu, enjoy exciting activities like darts and pool, and savor the moments that make us the heart of the community.",
        keywords: "Bar, Grill, Effingham, Community, Food, Drinks, Entertainment, Games, Pool, Darts, Wings, Cocktails, Burger, Billiards, Happy Hour, Fun, Hospitality, Atmosphere, All-You-Can-Eat, Classic, Experience, Fresh, Flavor, Handcrafted, Nightlife, Specials, Weekday, Bartenders, Cheers, Dinner, Lunch, Family, Friends, American, Favorites, Sports, Karaoke, #DannysBarAndGrill, Friday, Weekend, Celebrate, Anniversary, Local, Cheers, Party, Comfort, Delicious, Celebration, 22 Years.",
        message: "Cheers to one year of unforgettable moments at Danny's Bar & Grill! 🎉 Join us for another year of good food, great company, and endless fun. Thank you for making us Effingham's favorite spot!",
    },

    // Eloyalty Page
    eloyaltycontent: {
        title: "Join our eLoyalty Club for exclusive member benefits, specials, and events, earning Bucks for loyalty rewards.",
        description: "Discover the ultimate experience in flavor and hospitality at Danny's Bar & Grill in Effingham! Since 2000, we've been the go-to spot for good food, great drinks, and unforgettable entertainment. From crispy wings to jumbo bone-in wings, classic burgers, handcrafted cocktails, and more, our diverse menu and lively atmosphere make us the perfect destination for any occasion. Join us for a fantastic time, whether you're into pool, darts, or just relaxing with friends and family. The hype is real – come and be a part of it at Danny's Bar & Grill!",
        keywords: "Bar & Grill, Effingham, Flavorful Dining, Community Hub, 23 Years Strong, Lively Atmosphere, Handcrafted Cocktails, Pool Tables, Darts Games, Classic Burgers, Jumbo Wings, Family-Friendly, Hospitality, Entertainment Venue, Happy Hour, Special Events, Local Eats, All-You-Can-Eat Buffet, Friday Vibes, Fresh Ingredients, Karaoke Nights, Bartender Creations, Weekend Fun, New Year's Celebration, Holiday Wings, Merry Christmas, Thanksgiving Feast, College Football, National Vodka Day, National Whisky Sour Day, National Rum Day, National White Wine Day, Friday Fun, Game Night, Carry-Out Orders, Karaoke Party, Small Business Support, Legacy Building, #DannysBarAndGrill, Effingham Favorites, Good Times, Delicious Drinks, Baked Potato Creations, Fun Weekend Plans, Hungry Visitors, Bartender Expertise, Celebration Time, Winning Big, Foodie Delights, Flavorful Memories",
        message: "🎉 Celebrating 23 years of fun, flavor, and friends at Danny's Bar & Grill in Effingham! Join us for the perfect blend of delicious food, refreshing drinks, and exciting entertainment. Cheers to another year of making memories together! 🥂 #AnniversaryCelebration #DannysFamily",
    },
};

export default config;