import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import packageJson from "../../../../../../package.json";
import BarTop from "../../BarTop";
import BarBottom from "../../BarBottom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const backgroundColor = process.env.REACT_APP_BUTTON_COLOR;

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       <br></br>
//       Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
//       <Link color="inherit" target="_blank" href="https://servingintel.com/">
//         <img
//           className="silogo"
//           src={siteMedSrc + "si-logo" + ".svg"}
//           alt="ServingIntel"
//         />
//       </Link>{" "}
//       {packageJson.version}
//     </Typography>
//   );
// }

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const SignInSL = () => {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };
  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const [cookie, setCookie, removeCookie] = useCookies();

  const [firstName, setFirstName] = useState(cookie.firstName);
  const [roomNumber, setRoomNumber] = useState(cookie.roomNumber);

  useEffect(() => {
    if (cookie.firstName !== undefined && cookie.roomNumber !== undefined) {
      validateAccount();
    }
  }, []);

  const validateAccount = () => {
    if (!firstName || !roomNumber) {
      alertify.alert(
        "Error",
        "Invalid Account! Please input your account details",
        function () { }
      );
      return;
    }

    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
        serverId +
        "/" +
        siteId +
        "/" +
        firstName +
        "/" +
        roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
        serverId +
        "/" +
        siteId +
        "/" +
        storeId +
        "/" +
        firstName +
        "/" +
        roomNumber;
    axios
      .get(url)
      .then((response) => {
        if (response.data === null || response.data.length == 0) {
          alertify.alert(
            "Error",
            "Invalid Account! Please check your account details",
            function () { }
          );
          return;
        }

        const customer = response.data;

        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          sessionStorage.setItem("firstName", customer.FirstName);
          sessionStorage.setItem("lastName", customer.LastName);
          sessionStorage.setItem(
            "roomNumber",
            loginCustomerNumber == 1
              ? customer.CustomerNumber
              : customer.Address1
          );
          setCookie("firstName", customer.FirstName, { path: "/" });
          setCookie("roomNumber", customer.Address1, { path: "/" });
          setCookie("customerId", customer.CustomerID, { path: "/" });
          setCookie("customerDetails", customer, { path: "/" });
          const people = [];
          people.push({
            firstname: customer.FirstName,
            room: customer.Address1,
            custid: customer.CustomerID,
            lastname: customer.LastName,
          });
          if (cookie.customerList !== undefined) {
            const addPeople = [...cookie.customerList, ...people];
            const output = [
              ...new Map(addPeople.map((o) => [o.custid, o])).values(),
            ];
            setCookie("customerList", output, { path: "/" });
          } else {
            setCookie("customerList", people, { path: "/" });
          }

          if (customer.SIC_WebPin) {
            history.push("/logincode");
          } else {
            history.push("/UpdateCode");
          }
        }
      })
      .catch((error) => { });
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left",
            left: "0",
            right: "0",
            backgroundImage: `url(${siteMedSrc}signbg.png)`,
            minHeight: "100vh"
          }}
        >
          {/* <CssBaseline />
          <Grid
            item
            xs={false}
            sm={false}
            md={false}
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "left",
              left: "0",
              right: "0",
              backgroundImage: `url(${siteMedSrc}signbg.png)`,
            }}
          /> */}
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            justifyContent="center"
            alignItems="center"
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img
                    src={siteMedSrc + "logo" + ".png"}
                    width={logoWidth}
                    alt={`${restaurantName} logo`}
                    style={{ transform: "scale(2.5)", paddingTop: ".5rem", paddingBottom: "1rem" }}
                  />
                </div>
              ) : (
                // Add a default case here, for example:
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <Typography
                className="content"
                sx={{ mt: -1, textAlign: "center" }}
                component="h4"
                variant="h4"
              >
                <br />
                Hello. Please log into your account.
              </Typography>
              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  size="large"
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="First Name"
                  autoComplete="first-name"
                  autoFocus
                  variant="standard"
                  InputLabelProps={{
                    sx: { fontSize: "1.5rem" },
                  }}
                  InputProps={{
                    sx: { fontSize: "1.5rem" },
                  }}
                  sx={{ pt: 1.5 }}
                  onKeyUp={(e) => {
                    setFirstName(e.target.value);
                  }}
                />

                <TextField
                  size="large"
                  margin="normal"
                  required
                  fullWidth
                  name="room"
                  label={loginCustomerNumber == 1 ? "Badge #" : "Room #"}
                  type="room"
                  id="room"
                  autoComplete="room-number"
                  variant="standard"
                  InputLabelProps={{
                    sx: { fontSize: "1.5rem" },
                  }}
                  InputProps={{
                    sx: { fontSize: "1.5rem" },
                  }}
                  sx={{ pt: 1.5 }}
                  onKeyUp={(e) => {
                    setRoomNumber(e.target.value);
                  }}
                />

                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 5, mb: 2, fontWeight: "600", backgroundColor: backgroundColor }}
                  onClick={() => {
                    validateAccount();
                  }}
                >
                  <Typography className="content" sx={{ fontSize: "1.75rem", fontWeight: "600" }}>
                    LOGIN
                  </Typography>
                </Button>
              </Box>
            </Box>

            {/* {enableNewUI == 0 ? (
              <Copyright
                id="copyrignt"
                position="centered"
                sx={{ top: "auto", bottom: 0 }}
              />
            ) : null} */}
            {/* <FontSizeChanger
              targets={["#target .content"]}
              onChange={(element, newValue, oldValue) => {
                console.log(element, newValue, oldValue);
              }}
              options={{
                stepSize: 2,
                range: 5,
              }}
            customButtons={{
              up: (
                <AppBar
                  position="fixed"
                  sx={{
                    width: "90px",
                    boxShadow: 0,
                    top: "auto",
                    bottom: 237.5,
                    backgroundColor: "transparent",
                  }}
                >
                  <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton aria-label="add font size">
                      <Fab
                        color="primary"
                        aria-hidden="true"
                        sx={{ backgroundColor: "#3D5309" }}
                      >
                        <AddCircleOutlineIcon />
                      </Fab>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              ),
              down: (
                <AppBar
                  position="fixed"
                  sx={{
                    width: "90px",
                    boxShadow: 0,
                    top: "auto",
                    bottom: 180,
                    backgroundColor: "transparent",
                  }}
                >
                  <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton>
                      <Fab
                        color="primary"
                        aria-hidden="true"
                        sx={{ backgroundColor: "#765F00" }}
                      >
                        <RemoveCircleOutlineIcon />
                      </Fab>
                    </IconButton>
                  </Toolbar>
                </AppBar>
              ),
            }}
            /> */}
          </Grid>
        </Grid>
        {/* <BarTop />
        <BarBottom /> */}
      </ThemeProvider>
    </>
  );
};
