import React from "react";
import { useLocation, useHistory } from "react-router-dom";

const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment);
  const history = useHistory();

  const handleButtonClick = (segment, index) => {
    const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    history.push(newPath);
  };

  return (
    <nav>
      <ul style={{ listStyle: "none", padding: 0, display: "flex" }}>
        {pathSegments.map((segment, index) => (
          <li key={segment}>
            <button
              style={{
                textTransform: "capitalize",
                padding: "0rem 1rem",
                borderRadius: "15px",
                border: "1px solid #bebebe",
                backgroundColor: buttonColor,
                fontSize: "1rem",
                width: "100%",
                color: "white",
              }}
              onClick={() => handleButtonClick(segment, index)}
            >
              {segment}
            </button>
            {index < pathSegments.length - 1 && (
              <span style={{ margin: "0 0.5rem" }}> / </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
