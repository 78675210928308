import React, { useState } from "react";
import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import NavDrawer from "./NavDrawer";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

const NavMenu = ({ firstName, lastName }) => {
  const isMobile = useMediaQuery("(max-width:760px)");
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    sLHeader: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: "10!important",
    },
  }));
  const classes = useStyles();

  const match = useRouteMatch();

  const [appOpen, setAppOpen] = useState(false);
  const navList = [
    {
      label: "In-room Ordering",
      path: "/welcome",
    },
    {
      label: "My Transactions ",
      path: "/transactions",
    },
    {
      label: "My Profile",
      path: "/profile",
    },
  ];
  return (
    <AppBar position="fixed" open={appOpen} sx={{}}>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row!important",
          justifyContent: isMobile ? "space-between" : "normal",
          position: "relative",
        }}
        className={classes.sLHeader}
        id="headerLogoSticky"
      >
        {/* <IconButton
          sx={{
            display: isMobile ? "block" : "none",
            fontSize: "2rem",
            cursor: "pointer",
          }}
          onClick={(e) => console.log(e, "clicked")}
        >
          <Segment fontSize="inherit" />
        </IconButton> */}
        <NavDrawer navMenuList={navList} />
        <Box
          id="logo-center"
          sx={{
            mx: "auto",
            width: "auto",
            p: 0.5,
            // bgcolor: (theme) =>
            //   theme.palette.mode === "dark" ? "#101010" : "grey.50",
            // color: (theme) =>
            //   theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            // border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
            justifyContent: "flex-start",
          }}
        >
          <img
            className="App-logo"
            src={siteMedSrc + "logo" + ".png"}
            alt={restaurantName}
          />
        </Box>
        <Box padding={2}></Box>
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "calc(91% - 200px)",
              paddingLeft: 2,
              gap: 2,
              marginBottom: {
                xs: 2,
                lg: 0,
              },
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            }}
          >
            <Box>
              <Typography>
                Welcome back {firstName} {lastName}!
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" gap={2}>
              {navList.map((navMenu) => (
                <Link
                  underline="none"
                  color={(theme) =>
                    navMenu.path === match.url
                      ? theme.palette.primary.main
                      : theme.palette.grey[500]
                  }
                  onClick={() => history.push(navMenu.path)}
                >
                  <Typography>{navMenu.label}</Typography>
                </Link>
              ))}
            </Stack>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavMenu;
