import React from "react";
import "./sample.css";

export const SampleNutrition = () => {
  return (
    <div class="card-body">
      <div class="nutrition__content">
        <ul
          class="item-container product-detail__nutrition-heading"
          aria-label="Nutrition information"
        >
          <li class="item">
            <p class="value">
              <span aria-hidden="true" class="ng-binding">
                110Cal.
              </span>
              <span class="sr-only sr-only-pd ng-binding">110 Cal.</span>
            </p>

            <span class="metric">
              <span aria-hidden="true" class="ng-binding">
                Calories
              </span>
              <span class="sr-only sr-only-pd ng-binding">Calories </span>
            </span>
          </li>

          <li class="item">
            <p class="value">
              <span aria-hidden="true" class="ng-binding">
                0g
              </span>
              <span class="sr-only sr-only-pd ng-binding">0 grams</span>
            </p>

            <span class="metric">
              <span aria-hidden="true" class="ng-binding">
                Total Fat (0% DV)
              </span>
              <span class="sr-only sr-only-pd ng-binding">
                Total Fat (0% Daily Value)
              </span>
            </span>
          </li>

          <li class="item">
            <p class="value">
              <span aria-hidden="true" class="ng-binding">
                28g
              </span>
              <span class="sr-only sr-only-pd ng-binding">28 grams</span>
            </p>

            <span class="metric">
              <span aria-hidden="true" class="ng-binding">
                Total Carbs (10% DV)
              </span>
              <span class="sr-only sr-only-pd ng-binding">
                Total Carbs (10% Daily Value)
              </span>
            </span>
          </li>

          <li class="item">
            <p class="value">
              <span aria-hidden="true" class="ng-binding">
                0g
              </span>
              <span class="sr-only sr-only-pd ng-binding">0 grams</span>
            </p>

            <span class="metric">
              <span aria-hidden="true">Protein</span>
              <span class="sr-only sr-only-pd">Protein </span>
            </span>
          </li>
        </ul>

        <div
          class="nutrition__labels"
          role="region"
          aria-label="nutrition details"
        >
          <div class="product-detail__table-view-desktop d-none d-md-block">
            <ul
              data-ng-class="{'nutrition-display-table': true, 
                'one-column': 'three' == 'one',
                'two-column': 'three' == 'two',
                'three-column': 'three' == 'three'}"
              class="nutrition-display-table three-column"
            >
              <li class="label-item">
                <span class="metric">Saturated Fat:</span>
                <span aria-label="" class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 grams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Dietary Fiber:</span>
                <span aria-label="" class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 grams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Calcium:</span>
                <span aria-label="" class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Trans Fat:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">0 grams</span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Total Sugars:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    28g
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">28 grams</span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Iron:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Cholesterol:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0mg (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 milligrams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Vitamin D:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Potassium:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Sodium:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    30mg (1% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    30 milligrams (1% Daily Value)
                  </span>
                </span>
              </li>
            </ul>
          </div>

          <div class="row column-wrapper pc-border d-block d-md-none product-detail__table-view-mobile">
            <ul class="nutrition-display-table col-12">
              <li class="label-item">
                <span class="metric">Saturated Fat:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 grams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Trans Fat:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">0 grams</span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Cholesterol:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0mg (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 milligrams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Sodium:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    30mg (1% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    30 milligrams (1% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Dietary Fiber:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    0g (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    0 grams (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Total Sugars:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    28g
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">28 grams</span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Vitamin D:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Calcium:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Iron:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li class="label-item">
                <span class="metric">Potassium:</span>
                <span class="value text-right">
                  <span aria-hidden="true" class="ng-binding">
                    (0% DV)
                  </span>
                  <span class="sr-only sr-only-pd ng-binding">
                    (0% Daily Value)
                  </span>
                </span>
              </li>

              <li ng-show="serves!=''" class="ng-binding ng-hide">
                <span>Serves: </span>
              </li>
            </ul>
          </div>
        </div>

        <p class="nutrition__text nutrition__disclaimer">
          <a
            id="nutrition__disclaimer_footnotes"
            href="#legal-disclaimer-foornotes"
            tabindex="0"
            aria-label="skip to an important note on nutrition"
          >
            <span aria-hidden="true">*</span>
          </a>
          Percent Daily Values (DV) are based on a 2,000 calorie diet
        </p>
      </div>
    </div>
  );
};
