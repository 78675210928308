import React from 'react';

const addressMap = process.env.REACT_APP_ADDRESS_MAP;

export const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3099.4542291710923!2d-88.5458167!3d39.0277617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8873946449ee6f63%3A0xa78d955b567c24a!2sDanny&#39;s%20Bar%20%26%20Grill!5e0!3m2!1sen!2sph!4v1701358178127!5m2!1sen!2sph" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
  );
} 