import React, { useRef, useEffect, useState } from "react";
import "./keyboard.css";
import KioskBoard from "kioskboard";
import { Form, Input } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { setPromoCode } from "../../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

function Keypad() {
  const keyboardRef = useRef(null);
  const numpadRef = useRef(null);

  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
  };

  const initialValues = {
    message: "",
  };
  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  useEffect(() => {
    if (keyboardRef.current) {
      KioskBoard.run(keyboardRef.current, {
        language: "en",
        theme: "dark",
        keysArrayOfObjects: [
          {
            "0": "Q",
            "1": "W",
            "2": "E",
            "3": "R",
            "4": "T",
            "5": "Y",
            "6": "U",
            "7": "I",
            "8": "O",
            "9": "P"
          },
          {
            "0": "A",
            "1": "S",
            "2": "D",
            "3": "F",
            "4": "G",
            "5": "H",
            "6": "J",
            "7": "K",
            "8": "L"
          },
          {
            "0": "Z",
            "1": "X",
            "2": "C",
            "3": "V",
            "4": "B",
            "5": "N",
            "6": "M"
          }
        ]
      });
    }
  }, [keyboardRef]);

  useEffect(() => {
    if (numpadRef.current) {
      KioskBoard.run(numpadRef.current, {
        theme: "dark",
        keysArrayOfObjects: [
          {
            "0": "7",
            "1": "8",
            "2": "9"
          },
          {
            "0": "4",
            "1": "5",
            "2": "6"
          },
          {
            "0": "1",
            "1": "2",
            "2": "3"
          },
          {
            "0": "0",
            "1": "."
          }
        ]
      });
    }
  }, [numpadRef]);

  return (
    <Form provider={methods} onSubmit={handleSubmit}>
      <div className="Keyboard">
        <input
          className="inputFromKey eKioskInput"
          style={{
            fontSize: "1.25rem",
            margin: ".6rem 0rem",
            width: "100%",
            borderRadius: "4px",
            padding: "18.5px 14px",
          }}
          value={tempPromoCode}
          onChange={(e) => setTempPromoCode(e.target.value)}
          ref={keyboardRef}
          type="text"
          data-kioskboard-type="all"
          placeholder="Enter coupon code"
          autoComplete="off"
          data-kioskboard-placement="bottom"
        />
      </div>
    </Form>
  );
}

export default Keypad;
