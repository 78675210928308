import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Container, Col } from "react-bootstrap";
import { withRouter, useHistory, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountForm from "./components/AccountForm";
import MobileForm from "./components/MobileForm";
import PersonalDetails from "./components/PersonalDetails";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useCookies } from "react-cookie";
import { set } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

export const Account = () => {

  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_email",
    "sic_user_id",
  ]);

  // const [data, setData] = useState([]);
  const { id } = useParams();

  const [userData, setUserData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [anniversary, setAnniversary] = useState(new Date());
  // const [roomNumber, setRoomNumber] = useState("");
  // const [pin, setPin] = useState("");

  // GET fn
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://ecommv3.servingintel.com/customer/get-profile-by-email/" + serverId + "/" + siteId + "/" + cookieSIC.sic_email);
        const data = res.data;
        setFirstName(data.FirstName);
        setLastName(data.LastName);
        setEmailAddress(data.EmailAddress);
        setPhoneNumber(data.PhoneNumber);
        setDateOfBirth(data.DateOfBirth);
        setAnniversary(data.Anniversary);
        // setRoomNumber(data[0].RoomNumber);
        // setPin(data[0].PIN);
        // console.log(data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  // SUBMIT fn
  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!firstName.trim() || !lastName.trim() || !emailAddress.trim() || !phoneNumber.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        title: "Please fill all the required fields",
        showConfirmButton: false,
        timer: 1500,
      })
      return;
    }

    const updatedData = {
      EmailAddress: emailAddress,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
      DateOfBirth: dateOfBirth,
      Anniversary: anniversary,
    };
    // SERVER PROXY FOR CORS PREFLIGHT ERROR
    const proxy = "https://web-production-d6e6.up.railway.app/"
    const endpoint = proxy + "https://ecommv3.servingintel.com/customer/update-profile-by-email/" +
      serverId +
      "/" +
      siteId +
      "/" +
      cookieSIC.sic_email;

    // console.log(updatedData);
    // console.log(endpoint);

    try {
      const res = await axios.post(endpoint, updatedData);
      // SUCCESS
      Swal.fire({
        icon: 'success',
        title: 'Profile has been updated!',
        text: "You'll be redirected to the home page",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        // Redirect to home page
        setTimeout(() => {
          history.push('/');
        }, 1500)
      });
    } catch (error) {
      console.error("Failed due to: ", error);
    }
  }

  const getDOB = (val) => {
    setDateOfBirth(val);
  };

  const getAnniv = (val) => {
    setAnniversary(val);
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            alt="ServingIntel Logo"
            width="100%"
            height="100%"
            title="ServingIntel Logo"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  const history = useHistory();

  const theme = createTheme();

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Profile</title>
            <link rel="canonical" href="/register" />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <a href="/" target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".png"}
                width="100%"
                height="100%"
                title={restaurantName}
                alt={restaurantName}
                loading="lazy"
              />
            </a>
            <Typography
              component="h1"
              className="white"
              variant="h4"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Profile
            </Typography>
            <div className="col-lg-12">
              <div className="white">
                <h3>Make any changes</h3>
              </div>
            </div>

            <Paper
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
            >

              <React.Fragment>
                <Container className="formBG" style={{ padding: "0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    variant="outlined"
                    padding="0"
                  >
                    <TextField
                      value={firstName}
                      margin="dense"
                      required
                      fullWidth
                      id="firstname"
                      label="First Name"
                      name="firstname"
                      autoComplete="true"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <TextField
                      value={lastName}
                      margin="dense"
                      required
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      name="lastname"
                      autoComplete="true"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <TextField
                      value={emailAddress}
                      margin="dense"
                      disabled
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="true"
                      onChange={(e) => {
                        setEmailAddress(e.target.value);
                      }}
                      helperText="Email address is not allowed to be updated."
                    />
                    <TextField
                      value={phoneNumber}
                      margin="dense"
                      style={{ paddingBottom: "10px" }}
                      required
                      fullWidth
                      id="phoneNumber"
                      label="Phone Number"
                      name="phoneNumber"
                      autoComplete="true"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <KeyboardDatePicker
                          key="dateOfBirth"
                          style={{ paddingTop: "15px", paddingBottom: "15px", width: "100%" }}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label={dateOfBirth? "Birthday(MM/dd)": "Birthday(MM/dd) - Optional"}
                          format="MM/dd"
                          value={dateOfBirth ? dateOfBirth : today}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(date) => { 
                            setDateOfBirth(date);
                          }}
                          InputProps={{
                            style: { color: anniversary == "" ? "#f5f5db" : "#000000" },
                          }}
                          onClick={(e) => {
                            if (dateOfBirth === "") {
                              e.target.value = "";
                            }
                          }}
                        /> */}
                      <KeyboardDatePicker
                        key="dob"
                        style={{ paddingTop: "15px", width: "100%", paddingBottom: "15px" }}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={dateOfBirth ? "Birthday(MM/dd)" : "Birthday(MM/dd) - Optional"}
                        format="MM/dd"
                        value={dateOfBirth ? dateOfBirth : null}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => {
                          setDateOfBirth(date);
                        }}
                        InputProps={{
                          style: { color: dateOfBirth == "" ? "#f5f5db" : "#000000" },
                        }}
                        onClick={(e) => {
                          if (dateOfBirth === "") {
                            e.target.value = "";
                          }
                        }}
                      />
                      <KeyboardDatePicker
                        key="anniversary"
                        style={{ paddingTop: "15px", width: "100%" }}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={anniversary ? "Anniversary(MM/dd)" : "Anniversary(MM/dd) - Optional"}
                        format="MM/dd"
                        value={anniversary ? anniversary : null}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => {
                          setAnniversary(date);
                        }}
                        InputProps={{
                          style: { color: anniversary == "" ? "#f5f5db" : "#000000" },
                        }}
                        onClick={(e) => {
                          if (anniversary === "") {
                            e.target.value = "";
                          }
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <input type="text" value={dateOfBirth} hidden />  */}
                    {/* <input type="text" value={anniversary} hidden />  */}
                    {/* {userData.map((user) => (
                      <div key={user.CustomerID}>
                        <TextField
                        value={user.FirstName}
                        margin="dense"
                        required
                        fullWidth
                        id="firstname"
                        label="First Name"
                        name="firstname"
                        autoComplete="true"
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        />
                        <TextField
                        value={user.LastName}
                        margin="dense"
                        required
                        fullWidth
                        id="lastname"
                        label="Last Name"
                        name="lastname"
                        autoComplete="true"
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        />
                        <TextField
                        value={user.EmailAddress}
                        margin="dense"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="true"
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                        />
                        <TextField
                        defaultValue={user.PhoneNumber}
                        margin="dense"
                        required
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        autoComplete="true"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        />
                      </div>
                      
                    ))} */}
                    {/* <TextField
                        value="1998-01-19"
                        margin="dense"
                        required
                        fullWidth
                        type="date"
                        id="birthday"
                        label="Birthday"
                        name="birthday"
                        autoComplete="true"
                    /> */}
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                      onClick={handleUpdate}
                    >
                      Save Changes
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        history.push("/");
                      }}
                      size="large"
                      variant="contained"
                      style={{
                        fontWeight: "550",
                        backgroundColor: buttonColor,
                        borderColor: "none",
                        margin: ".5rem 0",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        history.push("/change-password");
                      }}
                      size="large"
                      variant="outlined"
                      className="btn-blue"
                      style={{
                        fontWeight: "550",
                        // backgroundColor: "#8c959f",
                        // borderColor: "none",
                        margin: ".5rem 0",
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </Container>
              </React.Fragment>
            </Paper>

          </Box>
          <Copyright />
        </Container>
        <CookieConsent
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="myAwesomeCookieName2"
          buttonClasses="btn btn-red"
          style={{ background: "#000" }}
          buttonStyle={{ fontSize: "16px" }}
          expires={365}
        >
          <div className="center">
            <Col xs={3} className="padding-bottom-20 center">
              <img
                width="100%"
                height="100%"
                title="Accept all cookies Icon"
                src={siteMedSrc + "cookie-icon" + ".svg"}
                size="15"
                alt="Accept all cookies Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="center"> Your Privacy </h3>
            </p>
          </div>

          <Typography>
            Greetings! Our website uses cookies so we may better serve you. By
            clicking “Accept all cookies” and by continuing to browse our site you
            are agreeing to our{" "}
          </Typography>
          <Typography>
            <a
              href="#"
              onClick={() => {
                history.push("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </CookieConsent>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(Account);
